<template>
  <div class="merchodrlines">
    <div class="row">
    <div class="panel-body row" style="padding-bottom:0px;">
      <!-- <div class="col-sm-12" style="padding-left:0px;">
        <div class="input-group col-sm-3" style="padding-left:15px;">
          <input type="text" v-model="searchKey" class="form-control" id="input-store" placeholder="Search Product" value="" name="voucher">          
        </div>
      </div>-->
      <!-- <div class="col-sm-5" style="padding-right:15px;">
        <button type="button" class="btn merchprof-sbtn" style="float: right;" @click="handleCreate">
          Add New
        </button>
        <button type="button" class="btn merchprof-sbtn" style="float: right;margin-right: 15px;" @click="handleCreateBulk()">
          Add New Bulk
        </button>
      </div>-->
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 customer-details-block">
        <h4>Customer Details:</h4>
      <!-- {{ order }} -->
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 cus-data--block" v-if="this.order.order_type == 2">
          Customer :
          {{ order.shipping_name != undefined ? order.shipping_name : "" }}
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 cus-data--block" v-else>
          Customer :
          {{ order.shipping_name != undefined ? order.shipping_name : "" }}
          <!-- {{relUserData.last_name != undefined ? relUserData.last_name : ""}} -->
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 cus-data--block" v-if="order.order_type != 2">
          <div style="display: inline-block; width: 100%;" v-show="relDelAddressData">
            Address :
            {{ relDelAddressData.address_line_1 }},
            {{ relDelAddressData.address_line_2 }},
            {{ relDelAddressData.city ? relDelAddressData.city.city_name : "" }}
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 cus-data--block" v-else>
          <div style="display: inline-block;width: 100%;">
            Address :
            {{ addressOrderType2 != undefined ? " " + addressOrderType2 : "" }}
          </div>
        </div>
 
        <div
          class="col-lg-4 col-md-12 col-sm-12 col-xs-12 cus-data--block"
          v-if="relUserData.fullObj && relUserData.fullObj.hasOwnProperty('payment_type')"
        >{{ relUserData.fullObj.payment_type === 1 ? "Payment Method : Supiri Pola Credit" :
           relUserData.fullObj.payment_type === 2 ? "Payment Method : Cash On Delivery" :
           relUserData.fullObj.payment_type === 3 ? "Payment Method : Bank Transfer" :
           relUserData.fullObj.payment_type === 4 ? "Payment Method : Card Payment" : "ERROR" }}</div>

        <div
          class="col-lg-4 col-md-6 col-sm-6 col-xs-6 deladdress-blc cus-data--block"
        >Mobile : {{ order.shipping_mobile }}</div>
        <div
          v-if="relUserData.secondary_mobile"
          class="col-lg-4 col-md-6 col-sm-6 col-xs-6 deladdress-blc cus-data--block"
        >Home Number : {{ relUserData.secondary_mobile }}</div>
        <div
          v-if="!relUserData.secondary_mobile"
          class="col-lg-4 col-md-6 col-sm-6 col-xs-6 deladdress-blc cus-data--block"
        >Home Number : Not Available</div>
        <div
          class="col-lg-4 col-md-12 col-sm-12 col-xs-12 deladdress-blc deladdress-blc--email cus-data--block"
        >Email : {{ relUserData.email }}</div>
        </div>
      </div>
      <!-- <div class="col-lg-12 col-md-12 col-sm-12" style="width: 100%;">
      <h4>Store Details :</h4>
        <table id="example" class="table table-striped table-border-out" style="width:100%;">
          <thead>
            <tr>
              <th scope="col" class="head-table">Store Name</th>
              <th scope="col" class="head-table">Email</th>
              <th scope="col" class="head-table">Mobile</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(col, id) in relUserData.fullObj.order_lines" :key="id">
              <td class="text-left  col-md-1" style="vertical-align: middle;">{{ col.simple_product_id.product_id.store.store_name }}</td>
              <td class="text-left  col-md-1" style="vertical-align: middle;">{{ col.simple_product_id.product_id.store.email }}</td>
              <td class="text-left  col-md-1" style="vertical-align: middle;">{{ col.simple_product_id.product_id.store.phone }}</td>
            </tr>
          </tbody>
        </table>
      </div>-->

      <div class="col-sm-12">
        <h2 class="title">Order Id: {{ relOrderId }}</h2>
        <div>Date: &nbsp;{{this.created_date}}</div>
        <div>Time: &nbsp;{{this.created_time}}</div>
        <h4 v-if="pOSReference" class="title">POS Reference: {{ pOSReference }}</h4>
      </div>
      </div>

      <div class="row tabcont--area" style="margin-right: 0px;margin-left: 0px;">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-wrapper merch--tab-wrapper" id="tabwrapper">
        <ul ref="tablist">
          <li
            @click="activePanel('pending')"
            data-target="#pending"
            :class="{ active: pendingActive }"
          >
            Pending
            <span
              style="font-weight: bolder; display:inline-block;width: 25px;height: 25px;background-color: #f1ca16;border-radius: 20px; font-size: 10px;color: #ffffff;line-height:25px;margin-left: 15px;"
            >{{ orderPendingArray.length }}</span>
          </li>
          <li
            @click="activePanel('processing')"
            data-target="#processing"
            :class="{ active: processingActive }"
          >
            Processing
            <span
              style="font-weight: bolder; display:inline-block;width: 25px;height: 25px;background-color: #ffa300;border-radius: 20px; font-size: 10px;color: #ffffff;line-height:25px;margin-left: 15px;"
            >{{ orderProcessingArray.length }}</span>
          </li>
          <li @click="activePanel('ready')" data-target="#ready" :class="{ active: readyActive }">
            Ready
            <span
              style="font-weight: bolder; display:inline-block;width: 25px;height: 25px;background-color: #0059aa;border-radius: 20px; font-size: 10px;color: #ffffff;line-height:25px;margin-left: 15px;"
            >{{ orderReadyPickUpArray.length }}</span>
          </li>
          <li
            @click="activePanel('shipped')"
            data-target="#shipped"
            :class="{ active: shippedActive }"
          >
            Shipped
            <span
              style="font-weight: bolder; display:inline-block;width: 25px;height: 25px;background-color: #A32BB9;border-radius: 20px; font-size: 10px;color: #ffffff;line-height:25px;margin-left: 15px;"
            >{{ orderShippedArray.length }}</span>
          </li>
          <li
            @click="activePanel('delivered')"
            data-target="#delivered"
            :class="{ active: deliveredActive }"
          >
            Delivered
            <span
              style="font-weight: bolder; display:inline-block;width: 25px;height: 25px;background-color: #4da130;border-radius: 20px; font-size: 10px;color: #ffffff;line-height:25px;margin-left: 15px;"
            >{{ orderDeliveredArray.length }}</span>
          </li>
          <li
            @click="activePanel('deliveryfailed')"
            data-target="#deliveryfailed"
            style="width: 145px;"
            :class="{ active: deliverfailActive }"
          >
            Delivery Failed
            <span
              style="font-weight: bolder; display:inline-block;width: 25px;height: 25px;background-color: rgb(225, 83, 55);border-radius: 20px; font-size: 10px;color: #ffffff;line-height:25px;margin-left: 15px;"
            >{{ orderDeliveryFailedArray.length }}</span>
          </li>
          <li
            @click="activePanel('cancelled')"
            data-target="#cancelled"
            style="width: 145px;"
            :class="{ active: cancelledActive }"
          >
            Cancelled
            <span
              style="font-weight: bolder; display:inline-block;width: 25px;height: 25px;background-color: #800000;border-radius: 20px; font-size: 10px;color: #ffffff;line-height:25px;margin-left: 15px;"
            >{{ ordercancelledArray.length }}</span>
          </li>
          <li
            @click="activePanel('indispute')"
            data-target="#indispute"
            style="width: 145px;"
            :class="{ active: inDisputeActive }"
          >
            Dispute
            <span
              style="font-weight: bolder; display:inline-block;width: 25px;height: 25px;background-color: rgb(0, 91, 170);border-radius: 20px; font-size: 10px;color: #ffffff;line-height:25px;margin-left: 15px;"
            >{{ orderInDisputeArray.length }}</span>
          </li>
          <!-- <li @click="activePanel('other')" data-target="#other">Other <span style="font-weight: bolder; display:inline-block;width: 25px;height: 25px;background-color: #f1ca16;border-radius: 20px; font-size: 10px;color: #ffffff;line-height:25px;margin-left: 15px;">{{orderOtherStatusArray.length}}</span></li>      -->
        </ul>
      </div>

        <div
          class="col-sm-12 panel-wrapper merch--panel-wrapper"
          id="panels"
          ref="panelscont"
          style="padding-bottom: 0px;"
        >
          <div id="pending" class="panel" :class="{ active: pendingActive }">
            <div v-show="orderPendingArray.length > 0">
              <input
                v-show="orderPendingArray"
                type="checkbox"
                name
                id
                @change="selectBulkPending('pending')"
                v-model="selectPendingAll"
                :disabled="false"
              />
              <span style="display: inline-block;margin-left: 12px;">Select All</span>
            </div>
            <div class="panel-body" v-for="(item, index) in orderPendingArray" :key="item.index">
              <div class="row" style="width: 100%;">
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <input
                    type="checkbox"
                    name
                    id
                    :value="item"
                    @click="selectSingleItem(index, 'pending')"
                    v-model="selectPendingToChange"
                  />
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 img-col">
                  <img
                    :src="item.simple_product_id.images[0].image"
                    style="display: block;width: 100%;"
                  />
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">{{ item.product_name }}</div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                >{{ item.qty > 1 ? item.qty + " Items" : item.qty + " Item" }}</div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                >Rs. {{ item.unit_price * item.qty.toFixed(2) }}</div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <span
                    class="badge"
                    style="background-color: #f1ca16;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                  >
                    <B>Pending</B>
                  </span>
                </div>
              </div>
            </div>
            <span
              v-if="orderPendingArray.length > 0"
            >Package Weight: {{ orderListWeight('pending')}} Kg</span>
            <div class="savebtns-block">
              
              <button
                class="btn btn-sm merchprof-cancel-sbtn"
                style="margin-right: 5px;"
                @click="changeCancelOder()"
                :disabled="!selectPendingToChange.length > 0"
                v-if="userRole !== 5 && userRole !== 6 && userRole !== 7"
              >Cancel</button>

              <button
                class="btn btn-sm merchprof-sbtn"
                @click="changePendingToPrcessing('pending')"
                :disabled="!selectPendingToChange.length > 0"
                v-if="userRole !== 5 && userRole !== 6 && userRole !== 7"
              >Change Status</button>
            </div>
          </div>
          <div id="processing" class="panel" :class="{ active: processingActive }">
            <div v-show="orderProcessingArray.length > 0">
              <input
                type="checkbox"
                name
                id="bprocess"
                @change="selectBulkProcessing('processing')"
                v-model="selectProcessingAll"
                :disabled="true"
              />
              <span style="display: inline-block;margin-left: 12px;">Select All</span>
            </div>
            <div class="panel-body" v-for="(item, index) in orderProcessingArray" :key="item.index">
              <div class="row" style="width: 100%;">
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <input
                    type="checkbox"
                    name
                    id
                    :value="item"
                    @click="selectSingleItem(index, 'processing')"
                    v-model="selectProcessingToChange"
                    :disabled="true"
                  />
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 img-col">
                  <img
                    :src="item.simple_product_id.images[0].image"
                    style="display: block;width: 100%;"
                  />
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">{{ item.product_name }}</div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                >{{ item.qty > 1 ? item.qty + " Items" : item.qty + " Item" }}</div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                >Rs. {{ item.unit_price * item.qty.toFixed(2) }}</div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <span
                    class="badge"
                    style="background-color: #ffa300;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                  >
                    <B>Processing</B>
                  </span>
                </div>
              </div>
            </div>
            <span
              v-if="orderProcessingArray.length > 0"
            >Package Weight: {{ orderListWeight('processing')}} Kg</span>
            
            <div class="savebtns-block">
              <h5><a style="color: red;" href="HelpCenter/Cochchi Packaging Guideline V.6.pdf" target="_blank">Cochchi Packaging Guideline</a></h5>
              <button
                class="btn btn-sm merchprof-sbtn"
                @click="changePrcessingToReady('processing')"
                :disabled="!selectProcessingToChange.length > 0"
                v-if="userRole !== 5 && userRole !== 6 && userRole !== 7"
              >Change Status</button>
            </div>
          </div>
          <div id="ready" class="panel" :class="{ active: readyActive }">
            <div v-show="orderReadyPickUpArray.length > 0">
              <input
                type="checkbox"
                name
                id
                @change="selectBulkReady('ready')"
                v-model="selectReadyAll"
                :disabled="true"
              />
              <span style="display: inline-block;margin-left: 12px;">Select All</span>
            </div>
            <div
              class="panel-body"
              v-for="(item, index) in orderReadyPickUpArray"
              :key="item.index"
            >
              <div class="row" style="width: 100%;">
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <input
                    type="checkbox"
                    name
                    id
                    :value="item"
                    @click="selectSingleItem(index, 'ready')"
                    v-model="selectReadyToChange"
                    :disabled="true"
                  />
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 img-col">
                  <img
                    :src="item.simple_product_id.images[0].image"
                    style="display: block;width: 100%;"
                  />
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">{{ item.product_name }}</div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                >{{ item.qty > 1 ? item.qty + " Items" : item.qty + " Item" }}</div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                >Rs. {{ item.unit_price * item.qty.toFixed(2) }}</div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <span
                    class="badge"
                    style="background-color: rgb(0, 89, 170);border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                  >
                    <B>Ready to Ship</B>
                  </span>
                </div>
              </div>
            </div>

            <div>
              <span
                v-if="orderReadyPickUpArray.length > 0"
              >Package Weight: {{ orderListWeight('ready')}} Kg</span>
            </div>

            <div class="savebtns-block" v-if="orderReadyPickUpArray.length > 0">
              <input
                  type="text"
                  class="form-control"
                  id="input-store"
                  placeholder="POS Reference Id"
                  v-model="pos_reference"
                  name="POS Reference Id"
                  style="width: 20%;"
                />
            </div>
            <div class="savebtns-block">
              <button
                class="btn btn-sm merchprof-sbtn"
                @click="changeReadyToShipped('ready')"
                :disabled="!selectReadyToChange.length > 0 ||  formIsValid.length <= 0"
                v-if="userRole !== 5 && userRole !== 6 && userRole !== 7"
              >Change Status</button>
            </div>
          </div>
          <div id="shipped" class="panel" :class="{ active: shippedActive }">
            <div class="panel-body" v-for="(item, index1) in orderShippedArray" :key="index1">
              <div class="row" style="width: 100%;">
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 img-col">
                  <img
                    :src="item.simple_product_id.images[0].image"
                    style="display: block;width: 100%;"
                  />
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">{{ item.product_name }}</div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                >{{ item.qty > 1 ? item.qty + " Items" : item.qty + " Item" }}</div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                >Rs. {{ item.unit_price * item.qty.toFixed(2) }}</div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <span
                    class="badge"
                    style="background-color: #A32BB9;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                  >
                    <B>Shipped</B>
                  </span>
                </div>
              </div>
            </div>
            <span
              v-if="orderShippedArray.length > 0"
            >Package Weight: {{ orderListWeight('shipped')}} Kg</span>
          </div>
          <div id="delivered" class="panel" :class="{ active: deliveredActive }">
            <div class="panel-body" v-for="(item, index) in orderDeliveredArray" :key="index">
              <div class="row" style="width: 100%;">
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 img-col">
                  <img
                    :src="item.simple_product_id.images[0].image"
                    style="display: block;width: 100%;"
                  />
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">{{ item.product_name }}</div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                >{{ item.qty > 1 ? item.qty + " Items" : item.qty + " Item" }}</div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                >Rs. {{ item.unit_price * item.qty.toFixed(2) }}</div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <span
                    class="badge"
                    style="background-color: #4da130;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                    v-if="item.order_line_status === 40"
                  >
                    <B>Delivered</B>
                  </span>
                  <span
                    class="badge"
                    style="background-color: #17c017;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                    v-if="item.order_line_status === 41"
                  >
                    <B>Delivery Confirmed</B>
                  </span>
                </div>
              </div>
            </div>
            <span
              v-if="orderDeliveredArray.length > 0"
            >Package Weight: {{ orderListWeight('delivered')}} Kg</span>
          </div>
          <div id="deliveryfailed" class="panel" :class="{ active: deliverfailActive }">
            <div class="panel-body" v-for="(item, index) in orderDeliveryFailedArray" :key="index">
              <div class="row" style="width: 100%;">
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 img-col">
                  <img
                    :src="item.simple_product_id.images[0].image"
                    style="display: block;width: 100%;"
                  />
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">{{ item.product_name }}</div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                >{{ item.qty > 1 ? item.qty + " Items" : item.qty + " Item" }}</div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                >Rs. {{ item.unit_price * item.qty.toFixed(2) }}</div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <span
                    class="badge"
                    style="background-color: rgb(225, 83, 55);border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                  >
                    <B>Delivery Failed</B>
                  </span><br/>
                  <span
                    class="badge"
                    style="background-color: rgb(225, 83, 55);border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                  >
                    <B>Delivery Fail Reason:- {{ item.delivery_failed_text }}</B>
                  </span>
                </div>
              </div>
            </div>
            <span
              v-if="orderDeliveryFailedArray.length > 0"
            >Package Weight: {{ orderListWeight('deliveryfailed')}} Kg</span>
          </div>

          <div id="cancelled" class="panel" :class="{ active: cancelledActive }">
            <div class="panel-body" v-for="(item, index) in ordercancelledArray" :key="index">
              <div class="row" style="width: 100%;">
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 img-col">
                  <img
                    :src="item.simple_product_id.images[0].image"
                    style="display: block;width: 100%;"
                  />
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">{{ item.product_name }}</div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                >{{ item.qty > 1 ? item.qty + " Items" : item.qty + " Item" }}</div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                >Rs. {{ item.unit_price * item.qty.toFixed(2) }}</div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <span
                    class="badge"
                    style="background-color: #800000;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                  >
                    <B>Cancelled</B>
                  </span>
                </div>
              </div>
            </div>
            <span
              v-if="ordercancelledArray.length > 0"
            >Package Weight: {{ orderListWeight('cancelled')}} Kg</span>
          </div>

           <div id="indispute" class="panel" :class="{ active: inDisputeActive }">
            <div class="panel-body" v-for="(item, index) in orderInDisputeArray" :key="index">
              <div class="row" style="width: 100%;">
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 img-col">
                  <img
                    :src="item.simple_product_id.images[0].image"
                    style="display: block;width: 100%;"
                  />
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">{{ item.product_name }}</div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                >{{ item.qty > 1 ? item.qty + " Items" : item.qty + " Item" }}</div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                >Rs. {{ item.unit_price * item.qty.toFixed(2) }}</div>

                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <span
                    v-if="item.order_line_status === 80"
                    class="badge"
                    style="background-color: rgb(0, 91, 170);border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                  >
                    <B>In Dispute</B>
                  </span>
                  <span
                    v-if="item.order_line_status === 90"
                    class="badge"
                    style="background-color: rgb(77, 161, 48);border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                  >
                    <B>Dispute Close</B>
                  </span>
                </div>

                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <button
                      v-if="inDisputeActive"
                      class="btn btn-sm merchprof-sbtn"
                      @click="haddleViewDisputeDetails(item._id)"
                  >Dispute View</button>
                </div>

              </div>
            </div>
            <span
              v-if="orderInDisputeArray.length > 0"
            >Package Weight: {{ orderListWeight('indispute')}} Kg</span>
          </div>
          <!-- Other panel starts -->
          <!-- <div id="other" class="panel">
              <div class="panel-body tble-panel-body"  style="overflow-x: scroll;">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding-left:0px;margin-bottom: 5px;">
          <div class="input-group col-sm-3" style="padding-left:15px;">
            <input type="text" v-model="searchKey" class="form-control" id="input-store" placeholder="Search Product" value="" name="voucher">          
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12" style="width: 100%;">
        <table id="example" class="table table-striped table-border-out" style="width:100%;">
          <thead>
            <tr>
              <th scope="col" class="head-table">Product Name</th>
              <th scope="col" class="head-table">Quantity</th>
              <th scope="col" class="head-table">Amount</th>
          <th scope="col" class="head-table">Status</th>-->
          <!-- <th scope="col" class="head-table">Action</th> -->
          <!-- </tr>
          </thead>
          <tbody>
            <div class="col-lg-12 col-md-12 col-sm-12" id="preview-area" v-if="blockLoading">
                <div class="row text-center">
                    <div class="sk-chase">
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                    </div>
                </div>
          </div>-->
          <!-- <tr v-for="(column, index) in tableItems" :key="index">
              <td class="text-left  col-md-3" style="vertical-align: middle;">{{column.product_name}}</td>
              <td style="padding-top: 26px; vertical-align: middle;" >{{column.qty}}</td>
              <td style="padding-top: 26px; vertical-align: middle;" >Rs. {{ column.total_charge}}</td>
              <td style="padding-top: 26px; vertical-align: middle;">
                <span class="badge" style="background-color: rgb(225, 83, 55);border-radius: 4px; font-size: 10px; padding: 4px 6px;" v-if="column.order_line_status === 0"><B>Cancelled</B></span>
                <span class="badge" style="background-color: #f1ca16;border-radius: 4px; font-size: 10px; padding: 4px 6px;" v-if="column.order_line_status === 1"><B>Pending</B></span>
                <span class="badge" style="background-color: #f1ca16;border-radius: 4px; font-size: 10px; padding: 4px 6px;" v-if="column.order_line_status === 10"><B>Processing</B></span>
                <span class="badge" style="background-color: rgb(0, 89, 170);border-radius: 4px; font-size: 10px; padding: 4px 6px;" v-if="column.order_line_status === 20"><B>Ready to Pickup</B></span>
                <span class="badge" style="background-color: #A32BB9;border-radius: 4px; font-size: 10px; padding: 4px 6px;" v-if="column.order_line_status === 30"><B>Shipped</B></span>
                <span class="badge" style="background-color: #4da130;border-radius: 4px; font-size: 10px; padding: 4px 6px;" v-if="column.order_line_status === 40"><B>Deliverd</B></span>
                <span class="badge" style="background-color: #17c017;border-radius: 4px; font-size: 10px; padding: 4px 6px;" v-if="column.order_line_status === 41"><B>Delivery Confirmed</B></span>
                <span class="badge" style="background-color: rgb(225, 83, 55);border-radius: 4px; font-size: 10px; padding: 4px 6px;" v-if="column.order_line_status === 50"><B>Fail to Delived</B></span>
                <span class="badge" style="background-color: rgb(0, 91, 170);border-radius: 4px; font-size: 10px; padding: 4px 6px;" v-if="column.order_line_status === 60"><B>Return to Store</B></span>
                <span class="badge" style="background-color: #005baa;border-radius: 4px; font-size: 10px; padding: 4px 6px;" v-if="column.order_line_status === 70"><B>Accept Return</B></span>
          </td>-->
          <!-- <td style="padding-top: 18px;" class="product-action-style"> -->
          <!-- <button type="button" @click="pushEdit(column._id)" class="btn btn-sm btn-primary" style="background-color: #2ba12f">Edit</button>&nbsp;
                <button type="button" class="btn btn-sm btn-danger" style="background-color: #e15437">Delete</button>&nbsp;
                <button @click="haddleAD(column._id, 1)" v-if="column.active_status === 1" type="button" class="btn btn-sm" style="background-color: #ffd338; color: white">Deactive</button>
          <button @click="haddleAD(column._id, 2)" v-if="column.active_status === 2" type="button" class="btn btn-sm btn-primary" >Active</button>-->
          <!-- <button class="btn btn-sm merchprof-sbtn" @click="changeStatus(column)">Change</button>
          </td>-->
          <!-- </tr>
          </tbody>
        </table>
      </div> 
    
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right">
          <div class="mt-3">
            <b-pagination 
            v-model="currentPage" 
            :per-page="pageSize"
            :total-rows="rowcount" 
            align="right"></b-pagination>
          </div>
        </div>
      
                    
              </div>
          </div>-->
          <!-- Other panel ends -->
        </div>
      </div>


      <div class="row">
        <div class="col-md-12">
            <div id="table-content" class="col-sm-12" style>
            <h4 class="title">Delivery Partner Details</h4>
            <table id="example" class="table table-striped table-border-out merch--deldetails">
              <thead>
                <tr>
                  <th scope="col" class="head-table" style="text-align: center;">Company Name</th>
                  <th scope="col" class="head-table" style="text-align: center;">Charge</th>
                  <th scope="col" class="head-table" style="text-align: center;">Email Address</th>
                  <th scope="col" class="head-table" style="text-align: center;">Mobile Number</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in delivery_partners" :key="index">
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >{{ item.company_name }}</td>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >Rs. {{ item.charge }}</td>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >{{ item.email }}</td>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >{{ item.mobile }}</td>
                </tr>
              </tbody>
            </table>
            </div>
        </div>
      </div>

      <!-- rider -->
      <div class="row">
        <div class="col-md-12">
          <div id="table-content" class="col-sm-12" style>
            <h4 class="title">Delivery Rider Details</h4>
            <table id="example" class="table table-striped table-border-out merch--delriderdetails">
              <thead>
                <tr>
                  <th scope="col" class="head-table" style="text-align: center;">Rider Name</th>
                  <th scope="col" class="head-table" style="text-align: center;">Mobile Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >{{ deliver_name !== "" ? deliver_name : "-" }}</td>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >{{ deliver_mobile !== "" ? deliver_mobile : "-" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div id="table-content" class="col-sm-12">
            <h4 class="title" style="    margin-top: 20px; margin-bottom: 20px;">Outlet Details</h4>
            <table id="example" class="table table-striped table-border-out merch--outletdetails">
              <thead>
                <tr>
                  <th scope="col" class="head-table" style="text-align: center;">Outlet Name</th>
                  <th scope="col" class="head-table" style="text-align: center;">Email Address</th>
                  <th scope="col" class="head-table" style="text-align: center;">Mobile Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >{{ outletDataView.store_name }}</td>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >{{ outletDataView.email }}</td>
                  <td
                    class="text-center"
                    style="vertical-align: middle; text-align: center; font-weight: 700;"
                  >{{ outletDataView.phone }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" style="margin-left: 0px; margin-right:0px;">
        <div class="bottom-form">
          <!-- <div class="row" style="margin-left: 0px; margin-right:0px;">		
            <div class="col-sm-12">
              <div class="col-sm-4">
              </div>
              <div class="col-sm-2">
                <label>Items</label>
                <h4>{{ orderLines.length!= undefined ? orderLines.length : "" }}</h4>
              </div>

              <div class="col-sm-2">
                <label>Sub Total</label>
                <h4>Rs. {{ subtotal }}</h4>
              </div>

              <div class="col-sm-2">
                <label>Shipping and Handling fee</label>
                 <h4>Rs. {{ order.total_delivery_charge!= undefined ? order.total_delivery_charge : ""}}</h4>
              </div>

              <div class="col-sm-2">
                <label>Total</label>
                 <h4>Rs. {{ order.total_charge != undefined ? order.total_charge : " "  }}</h4>
              </div>
            </div>
          </div>-->

          <!-- New -->
          <div class="row" style="margin-left: 0px;margin-right: 0px;">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
              style="padding-left: 0px;padding-right: 0px;"
            >
              <table class="table merch--totalvalues">
                <thead>
                  <tr>
                    <th>Items In Cart</th>
                    <th>Sub Total</th>
                    <th>Shipping and Handling Fee</th>
                    <th>Voucher/Coupon Discount</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;">
                      <h4>
                        {{
                        orderLines.length != undefined
                        ? itemInCart()
                        : ""
                        }}
                      </h4>
                    </td>
                    <td style="text-align: center;">
                      <h4>Rs. {{ subtotal }}</h4>
                    </td>
                    <td style="text-align: center;">
                      <h4>
                        Rs.
                        {{
                        orderLines.length != undefined
                        ? shippingFee
                        : "0.00"
                        }}
                      </h4>
                    </td>
                    <td style="text-align: center;">
                      <h4>
                        Rs. -
                        {{
                          order.allCoupenDiscounts + order.allVoucherDiscounts
                        }}
                      </h4>
                    </td>
                    
                    <td style="text-align: center;">
                      <h4 v-if="subtotal + shippingFee - (order.allCoupenDiscounts + order.allVoucherDiscounts) >= 0 ">
                        Rs.
                        {{
                          subtotal + shippingFee - (order.allCoupenDiscounts + order.allVoucherDiscounts) 
                        }}
                      </h4>
                      <h4 v-if="subtotal + shippingFee - (order.allCoupenDiscounts + order.allVoucherDiscounts) < 0 ">
                        Rs. 00.00
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style="padding-top: 3%;"
      class="modal fade"
      id="trackCancelModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ratingModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ratingModalLongTitle">Cancel Order Reason</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top:-20px;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 order-list-top">
               
                <div class="form-group required">
                <label>Reason</label>
                <select class="form-control select-arrow"  v-model="order_cancel_reason" @change="changeReason($event.target.value)">
                    <option value=""> --- Please Select --- </option>
                    <option v-for="(item, val) in rejectReason" :key="val" :value="item._id" >{{ item.reason}}</option>
                </select><br/>

                <textarea
                  v-if="resonIsShow === true"
                  rows="10"
                  type="text"
                  placeholder="Description"
                  class="form-control"
                  style="min-height: 80px;"
                  v-model="order_cancel_reason_text"
                />
                </div>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div>
              <button type="button" class="btn btn-secondary buyerprof-sbtn" data-dismiss="modal">Close</button>
              <button class="btn btn-primary merchprof-sbtn" @click="changeCancelSubmit()" :disabled="btnDisable">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import mixinProduct from "@/mixins/merchant/APIProduct";
import mixinOrder from "@/mixins/merchant/APIOrder";

export default {
  mixins: [mixinProduct, mixinOrder],
  data: function() {
    return {
      userRole: "",
      orderLines: [],
      statusObj: {},
      breadcrumb: [
        {
          text: "Order List",
          href: "/merchant-order-group",
          active: false
        },
        {
          text: "Order Configure",
          active: true
        }
      ],
      subtotal: 0,
      delivery_partners: [],
      delivery_partner_commpany_name: "",
      pageSize: 25,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      tableItems: [],
      storeListData: [],
      blockLoading: true,
      relOrderId: "",
      pOSReference: "",
      created_time:"",
      created_date:"",
      orderPendingArray: [],
      orderProcessingArray: [],
      orderReadyPickUpArray: [],
      orderShippedArray: [],
      orderDeliveredArray: [],
      orderDeliveryFailedArray: [],
      ordercancelledArray: [],
      orderInDisputeArray: [],
      orderOtherStatusArray: [],
      selectPendingAll: true,
      selectPendingToChange: [],
      selectProcessingAll: true,
      selectProcessingToChange: [],
      selectReadyAll: true,
      selectReadyToChange: [],
      relUserData: {},
      relDelAddressData: "",
      order: {},
      addressOrderType2: "",
      outletDataView: {},
      pendingActive: true,
      processingActive: false,
      readyActive: false,
      shippedActive: false,
      deliveredActive: false,
      deliverfailActive: false,
      cancelledActive: false,
      inDisputeActive: false,
      pos_reference: '',
      deliver_name: '',
      deliver_mobile: '',
      shippingFee: 0,

      rejectReason: [],
      resonIsShow: false,
      order_cancel_reason: "",
      order_cancel_reason_text: "",

      btnDisable: true
    };
  },
  watch: {
    "$store.state.merchant_selectedstore": function() {
      this.handleGetProductList();
    },
    searchKey: function(val) {
      this.searchCustomers(val);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {
      this.searchCustomers(this.searchKey);
    },
    order_cancel_reason_text: function(val) {
      if (val !== "") {
        this.btnDisable = false;
      } else {
        this.btnDisable = true;
      }
    }
  },
  created() {
    this.handleBreadcrumb();
    this.handleGetStores();
    this.handleGetProductList();
    this.haddleGetMerchatCancelReason();
    let mdata = JSON.parse(localStorage.merchant_data);
    this.userRole = mdata.role;
    // this.activePanel('pending');
  },
  mounted() {
    // this.tabPanelInitiate();
  },
  computed: {
    formIsValid() {
      return (
        this.pos_reference
      );
    }
  },
  methods: {
    haddleGetMerchatCancelReason: async function() {
      try {
        let response = await this.haddleGetMerchatCancelReasonList();
        this.rejectReason = response;
      } catch (error) {
        throw error;
      }
    },
    changeReason: function(event) {

      let reasonObj = this.rejectReason.filter(function (obj) {
        return obj._id === event;
      });

      this.resonIsShow = false;

      this.order_cancel_reason = "";
      this.order_cancel_reason_text = "";

      this.order_cancel_reason = event;
      this.order_cancel_reason_text = reasonObj[0].reason;

      this.btnDisable = false;

      if (event && event !== "5fa26a936c54a27aa16d31e7") {
        this.resonIsShow = false;
        this.btnDisable = false;
      } else {
        this.resonIsShow = true;
        this.btnDisable = true;
        this.order_cancel_reason_text = "";
      }

    },
    orderListWeight(type) {
      switch (type) {
        case "pending":
          return this.calculateWeight(this.orderPendingArray);
          break;

        case "processing":
          return this.calculateWeight(this.orderProcessingArray);
          break;

        case "ready":
          return this.calculateWeight(this.orderReadyPickUpArray);
          break;

        case "shipped":
          return this.calculateWeight(this.orderShippedArray);
          break;

        case "delivered":
          return this.calculateWeight(this.orderDeliveredArray);
          break;

        case "deliveryfailed":
          return this.calculateWeight(this.orderDeliveryFailedArray);
          break;

        case "cancelled":
          return this.calculateWeight(this.ordercancelledArray);
          break;

        case "indispute":
          return this.calculateWeight(this.orderInDisputeArray);
          break;

        default:
          return 0;
          break;
      }
    },
    calculateWeight(array) {
      var weight = 0;
      for (let index = 0; index < array.length; index++) {
        const element = array[index];

        if (
          element.simple_product_id.product_id.hasOwnProperty("package_weight")
        ) {
          var packweight = parseFloat(
            element.simple_product_id.product_id.package_weight
          ).toFixed(2);
          weight += Number(packweight) * element.qty;
        }
      }
      return parseFloat(weight).toFixed(2);
    },
    populateDeliveryPartners: function() {
      this.delivery_partners = [];
      let dp_arraytxt = [];
      let dp_charges = [];
      for (let index = 0; index < this.orderLines.length; index++) {
        const element = this.orderLines[index];
        this.delivery_partner_commpany_name = element.delivery_partner.company_name;
        let delivery_partner = {
          company_name: element.delivery_partner.company_name,
          mobile: element.delivery_partner.mobile,
          email: element.delivery_partner.email,
          _id: element.delivery_partner._id
        };
        dp_arraytxt.push(JSON.stringify(delivery_partner));
        dp_charges.push({
          _id: element.delivery_partner._id,
          charge: element.delivery_charge
        });
      }

      var uniquedp = dp_arraytxt.filter(function(elem, index, self) {
        return index === self.indexOf(elem);
      });

      for (let index = 0; index < uniquedp.length; index++) {
        const element = uniquedp[index];
        let del_charge = 0;
        let obj = JSON.parse(element);
        dp_charges.forEach(element => {
          if (element._id == obj._id) {
            del_charge = element.charge + del_charge;
          }
        });
        obj.charge = del_charge;
        this.delivery_partners.push(obj);
      }
    },
    tabPanelInitiate: function() {
      // Tab content
      // const tabList = document.querySelector("#tabwrapper ul");
      // const tabList = this.$refs.tablist;
      // console.log(tabList);
      // var tabListItems = tabList.querySelectorAll("li");
      // console.log(tabListItems);
      // tabListItems[0].className = "active";
      // tabListItems.forEach(function(tabItem) {
      //     tabItem.addEventListener("click", function(event) {
      //         tabListItems.forEach(function(item) {
      //             item.classList.remove("active");
      //         });
      //         event.target.classList.add("active");
      //         this.activePanel(event.target.innerHTML.toLowerCase());
      //     });
      // });
      // const panelList = document.querySelectorAll("#panels .panel");
      // console.log(panelList);
      // panelList[0].classList.add("active");
    },
    activePanel: function(panel) {
      const tabList = document.querySelector("#tabwrapper ul");

      var tabListItems = tabList.querySelectorAll("li");

      tabListItems.forEach(function(tab) {
        if (tab.dataset.target == "#" + panel) {
          tab.classList.add("active");
        } else {
          tab.classList.remove("active");
        }
      });

      // alert(panel);
      const panelList = document.querySelectorAll("#panels .panel");
      panelList.forEach(function(panelItem) {
        if (panelItem.id == panel) {
          panelItem.classList.add("active");
        } else {
          panelItem.classList.remove("active");
        }
      });
    },
    selectBulkPending: async function(OrdStatus) {
      // alert(OrdStatus);
      // alert(this.selectPendingAll);

      this.selectPendingToChange = [];

      if (this.selectPendingAll == true) {
        this.selectPendingToChange = this.orderPendingArray;
      } else {
        this.selectPendingToChange = [];
      }

      //   console.log("== To save ststus chng =====");

      //   console.log(this.selectPendingToChange);
    },
    changeCancelOder: function() {
      $("#trackCancelModalCenter").modal("show");
    },
    changeCancelSubmit: async function() {
      var ids = [];
      var statusObj = {};

      this.selectPendingToChange.forEach(obj => {
        ids.push(obj._id);
      });

      statusObj.line_ids = ids;
      statusObj.order_env_number = this.relOrderId;

      statusObj.order_cancel_reason_text = this.order_cancel_reason_text;
      if (this.order_cancel_reason === "5fa276166c54a27aa16d31ef") {
        // statusObj.order_cancel_reason = this.order_cancel_reason;
      } else {
        statusObj.order_cancel_reason = this.order_cancel_reason;
      }

      try {
        await this.putchangeOderCancel(statusObj);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Status Updated Successfully",
          showConfirmButton: false,
          timer: 1500
        });
        this.handleGetProductList();
        this.activePanel("cancelled");
        $("#trackCancelModalCenter").modal("hide");
      } catch (error) {
        throw error;
      }
    },
    changePendingToPrcessing: async function(ordStatus) {
      // alert(ordStatus);

      var ids = [];
      var statusObj = {};

      this.selectPendingToChange.forEach(obj => {
        ids.push(obj._id);
      });

      statusObj.ids = ids;
      statusObj.order_no = this.relOrderId;

      try {
        let response = await this.chgPendingToProcessing(statusObj);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Status Updated Successfully",
          showConfirmButton: false,
          timer: 1500
        });
        this.handleGetProductList();
        // location.reload();
        this.activePanel("processing");
      } catch (error) {
        throw error;
      }
    },
    selectBulkProcessing: async function(OrdStatus) {
      //  alert(OrdStatus);
      //  alert(this.selectProcessingAll);

      this.selectProcessingToChange = [];

      if (this.selectProcessingAll == true) {
        this.selectProcessingToChange = this.orderProcessingArray;
      } else {
        this.selectProcessingToChange = [];
      }
    },
    changePrcessingToReady: async function(ordStatus) {
      // alert(ordStatus);

      var ids = [];
      var statusObj = {};

      this.selectProcessingToChange.forEach(obj => {
        ids.push(obj._id);
      });

      statusObj.ids = ids;
      statusObj.order_no = this.relOrderId;

      try {
        let response = await this.chgProcessingToReady(statusObj);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Status Updated Successfully",
          showConfirmButton: false,
          timer: 1500
        });
        this.handleGetProductList();
        // location.reload();
        this.activePanel("ready");
      } catch (error) {
        throw error;
      }
    },
    selectBulkReady: async function(OrdStatus) {
      //  alert(OrdStatus);
      //  alert(this.selectReadyAll);

      this.selectReadyToChange = [];

      if (this.selectReadyAll == true) {
        this.selectReadyToChange = this.orderReadyPickUpArray;
      } else {
        this.selectReadyToChange = [];
      }
    },
    changeReadyToShipped: async function(ordStatus) {
      // alert(ordStatus);

      var ids = [];
      var statusObj = {};

      this.selectReadyToChange.forEach(obj => {
        ids.push(obj._id);
      });

      statusObj.ids = ids;
      statusObj.order_no = this.relOrderId;
      statusObj.pos_reference = this.pos_reference;

      // console.log("Check Obj Save");
      // console.log(statusObj);

      try {
        let response = await this.chgReadyToShipped(statusObj);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Status Updated Successfully",
          showConfirmButton: false,
          timer: 1500
        });
        this.handleGetProductList();
        // location.reload();
        this.activePanel("shipped");
      } catch (error) {
        throw error;
      }
    },
    selectSingleItem: function(index, status) {
      //   alert(index);
      //    alert(status);
    },
    haddleAD: async function(id, status) {
      if (status === 1) {
        this.statusObj._id = id;
        this.statusObj.active_status = 2;
      } else {
        this.statusObj._id = id;
        this.statusObj.active_status = 1;
      }
      try {
        await this.putProductStatus(this.statusObj);
        this.handleGetProductList();
        this.$swal
          .fire({
            position: "center",
            type: "success",
            title: "Successfully changed product status",
            showConfirmButton: false,
            timer: 1500
          })
          .then(function() {});
      } catch (error) {
        this.$swal
          .fire({
            position: "center",
            type: "error",
            title: "Cant change product status",
            showConfirmButton: false,
            timer: 1500
          })
          .then(function() {});
      }
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    calculateSubtotal: function() {
      this.subtotal = 0

      let objArray = this.orderLines.filter(el => {
          return el.order_line_status !== 0;
        });

      objArray.forEach(item => {
        this.subtotal = (item.unit_price * item.qty) + this.subtotal;
      });
    },
    calculateShippingFee: function() {
      this.shippingFee = 0

      let objArray = this.orderLines.filter(el => {
          return el.order_line_status !== 0;
        });

      objArray.forEach(item => {
        this.shippingFee = item.delivery_charge + this.shippingFee;
      });
    },
    itemInCart: function() {

      let objArray = this.orderLines.filter(el => {
          return el.order_line_status !== 0;
        });

      return objArray.length;
    },
    handleCreate: function() {
      this.$router.push({ name: "Product Configure", params: { id: 0 } });
    },
    handleCreateBulk: function() {
      this.$router.push({ name: "Product Configure Bulk" });
    },
    handleProduct: function() {
      this.$router.push({ name: "Merchant Product", params: { id: 0 } });
    },
    handleGetStores: async function(page) {
      try {
        this.searchCustomers("");
      } catch (error) {
        throw error;
      }
    },
    handleGetProductList: async function() {
      var orderId = this.$route.params.id
        ? this.$route.params.id
        : localStorage.order_group_id;

      // console.log("==== Order Id ======");
      // console.log(orderId);

      try {
        // if(this.$store.state.merchant_selectedstore === "-1"){
        //   let response = await this.getProducts();
        //   this.storeListData = response;
        // } else {
        //   if(this.$store.state.merchant_selectedstore != null){
        let response = await this.getOrderLinesByOrderId(orderId);


        this.outletDataView = response.order_lines[0].simple_product_id.product_id.store;
        response.allCoupenDiscounts = 0;
        response.allVoucherDiscounts = 0;
        let couponVal = 0
        let voucherVal = 0
        // calculate total coupon & voucher amount
        for (let index = 0; index < response.order_lines.length; index++) {
          const element = response.order_lines[index];
          couponVal += element.coupon_discount_amount; 
          voucherVal += element.voucher_discount;
          
        }

        response.allCoupenDiscounts = couponVal;
        response.allVoucherDiscounts = voucherVal;

        this.created_date = response.order_lines[0].date;
        // let time = new Date(response.order_lines[0].created_date);
        // let createdHours = new Date(time).getHours();
        // let createdMinutes =  new Date(time).getMinutes();
        // let createdSeconds =  new Date(time).getSeconds();
        // this.created_time = createdHours + ":" + createdMinutes + ":" + createdSeconds;
        this.created_time = moment(response.order_lines[0].created_date).format("hh:mm A")

        // this.created_time = response.order_lines[0].created_date.split("T")[1].split(".0")[0];

        this.deliver_name = response.deliver_name;
        this.deliver_mobile = response.deliver_mobile;

        var orderLinesArray = [];
        var orderLinesArray = response.order_lines;
        this.order = response;
        this.orderLines = response.order_lines;
        this.calculateSubtotal();
        this.calculateShippingFee();
        this.populateDeliveryPartners();
        this.orderPendingArray = [];
        this.orderProcessingArray = [];
        this.orderReadyPickUpArray = [];
        this.orderShippedArray = [];
        this.orderDeliveredArray = [];
        this.orderDeliveryFailedArray = [];
        this.ordercancelledArray = [];
        this.orderInDisputeArray = [];
      
        orderLinesArray.forEach(element => {

          if (element.order_line_status == 1) {
            this.orderPendingArray.push(element);
          } else if (element.order_line_status == 10) {
            this.orderProcessingArray.push(element);
          } else if (element.order_line_status == 20) {
            this.orderReadyPickUpArray.push(element);
          } else if (element.order_line_status == 30) {
            this.orderShippedArray.push(element);
          } else if (
            element.order_line_status == 40 ||
            element.order_line_status == 41
          ) {
            this.orderDeliveredArray.push(element);
          } else if (element.order_line_status == 50) {
            this.orderDeliveryFailedArray.push(element);
          } else if (element.order_line_status == 0) {
            this.ordercancelledArray.push(element);
          } else if (element.order_line_status == 80 || element.order_line_status == 90) {
            this.orderInDisputeArray.push(element);
          } else {
            this.orderOtherStatusArray.push(element);
          }
        });

        this.selectPendingToChange = this.orderPendingArray;
        this.selectProcessingToChange = this.orderProcessingArray;
        this.selectReadyToChange = this.orderReadyPickUpArray;

        // Select active tab & panel when loading
        if (this.orderPendingArray.length >= 1) {
          this.pendingActive = true;
          this.processingActive = false;
          this.readyActive = false;
          this.shippedActive = false;
          this.deliveredActive = false;
          this.deliverfailActive = false;
          this.cancelledActive = false;
          this.inDisputeActive = false;
        } else if (this.orderProcessingArray.length >= 1) {
          this.processingActive = true;
          this.pendingActive = false;
          this.readyActive = false;
          this.shippedActive = false;
          this.deliveredActive = false;
          this.deliverfailActive = false;
          this.cancelledActive = false;
          this.inDisputeActive = false;
        } else if (this.orderReadyPickUpArray.length >= 1) {
          this.readyActive = true;
          this.pendingActive = false;
          this.processingActive = false;
          this.shippedActive = false;
          this.deliveredActive = false;
          this.deliverfailActive = false;
          this.cancelledActive = false;
          this.inDisputeActive = false;
        } else if (this.orderShippedArray.length >= 1) {
          this.shippedActive = true;
          this.pendingActive = false;
          this.processingActive = false;
          this.readyActive = false;
          this.deliveredActive = false;
          this.deliverfailActive = false;
          this.cancelledActive = false;
          this.inDisputeActive = false;
        } else if (this.orderDeliveredArray.length >= 1) {
          this.deliveredActive = true;
          this.pendingActive = false;
          this.processingActive = false;
          this.readyActive = false;
          this.shippedActive = false;
          this.deliverfailActive = false;
          this.cancelledActive = false;
          this.inDisputeActive = false;
        } else if (this.orderDeliveryFailedArray.length >= 1) {
          this.deliverfailActive = true;
          this.pendingActive = false;
          this.processingActive = false;
          this.readyActive = false;
          this.shippedActive = false;
          this.deliveredActive = false;
          this.cancelledActive = false;
          this.inDisputeActive = false;
        } else if (this.ordercancelledArray.length >= 1) {
          this.deliverfailActive = false;
          this.pendingActive = false;
          this.processingActive = false;
          this.readyActive = false;
          this.shippedActive = false;
          this.deliveredActive = false;
          this.cancelledActive = true;
          this.inDisputeActive = false;
        } else if (this.orderInDisputeArray.length >= 1) {
          this.deliverfailActive = false;
          this.pendingActive = false;
          this.processingActive = false;
          this.readyActive = false;
          this.shippedActive = false;
          this.deliveredActive = false;
          this.cancelledActive = false;
          this.inDisputeActive = true;
        }

        if (this.order.order_type == 2) {
          this.relUserData.fullObj = this.order;
          this.relUserData.name = this.order.shipping_name;
          this.relUserData.mobile = this.order.shipping_mobile;
          this.relUserData.email = this.order.user_email;
          this.relOrderId = this.order.order_env_number;
          this.pOSReference = response.pos_reference;
          this.addressOrderType2 = this.order.robo_shipping_address;
        } else {
          this.pOSReference = response.pos_reference;
          this.relOrderId = response.order_env_number;
          this.relUserData = response.user_id;
          this.relUserData.fullObj = response;
          this.relDelAddressData = response.address_id;
        }
        if(this.relUserData.secondary_mobile.includes('+')) {
          this.relUserData.secondary_mobile = this.relUserData.secondary_mobile.substring(1)
        }
        // Check duplicate outlet data & fetch
        var displayOutlets = [];
        var outlets = [];
        for (var i = 0, len = orderLinesArray.length; i < len; i++) {
          if (
            outlets.indexOf(
              orderLinesArray[i].simple_product_id.product_id.store._id
            ) > -1
          ) {
            // console.log('Duplicate type');
          } else {
            // console.log('New type');
            outlets.push(
              orderLinesArray[i].simple_product_id.product_id.store
            );
            displayOutlets.push(orderLinesArray[i]);
          }
        }

        this.storeListData = this.orderOtherStatusArray;
        this.blockLoading = false;
        this.searchCustomers("");
      } catch (error) {
        throw error;
      }
    },

    searchCustomers: function(keyword) {
      let pagesize = parseInt(this.pageSize);
      if (!keyword || keyword.length === 0) {
        this.tableItems = this.storeListData.slice(0, pagesize);
        this.rowcount = this.storeListData.length;
      } else {
        this.tableItems = this.storeListData
          .filter(obj => {
            {
              return (
                (obj.product_name &&
                  obj.product_name
                    .toLowerCase()
                    .includes(keyword.toLowerCase())) ||
                (obj.description &&
                  obj.description.toLowerCase().includes(keyword.toLowerCase()))
              );
            }
          })
          .slice(0, pagesize);
        this.rowcount = this.storeListData.length;
      }
    },
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      let endsize = pagesize * currentPage;
      let startsize = 0;
      if (currentPage === 1) {
        startsize = 0;
      } else {
        startsize = endsize - pagesize;
      }
      this.tableItems = this.storeListData.slice(startsize, endsize);
    },
    pushEdit(pid) {
      this.$router.push({ name: "Product Configure", params: { id: pid } });
    },
    changeStatus: function(item) {
      //   localStorage.setItem("order_config_line_id",item._id);
      this.$router.push({ name: "Order Configure", params: { id: item._id } });
    },
    haddleViewDisputeDetails: function(id) {
      this.$router.push({ name: "Merchant Dispute Lines", params: { id: id } });
    }
  }
};
</script>
<style>
input[type="checkbox"][readonly] {
  pointer-events: none;
}
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.product-action-style {
  min-width: 128px;
  width: 230px;
}
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  z-index: 5;
}
.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}
.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color:#5779ae;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}
.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}
@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
#preview-area {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.head-table {
  background: white !important;
}
.table-border-out {
  border: 1px solid #ddd;
}
.merchodrlines .merchprof-cancel-sbtn {
  background:#800000;
  color: white;
  border: none;
}
.merchodrlines .merchprof-sbtn:hover {
  color: #000000;
}

.merchodrlines .merchprof-sbtn {
  background:#5779ae;
  color: #ffffff;
  border: none;
}
.merchodrlines .merchprof-cancel-sbtn:hover {
  color: #000000;
}


.tab-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  z-index: 100;
}
.tab-wrapper ul {
  display: flex;
  list-style: none;
  padding: 0px;
  flex-basis: 100%;
  flex-wrap: nowrap;
  /* margin-bottom: 5px; */
}
.tab-wrapper ul li {
  height: 35px;
  line-height: 35px;
  min-width: 130px;
  background: #ffffff;
  /* margin-right: 5px; */
  /* border-radius: 4px; */
  text-align: center;
  cursor: pointer;
  color: #000000;
  border-bottom: 1px solid #d8d8d8;
}
.tab-wrapper ul li.active {
  /* background: #ffd338; */
  color: #ffd338;
  border-top: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-bottom: 0px solid #d8d8d8;
}
.tab-wrapper ul li:hover {
  background: #eeeeee !important;
  color: #ffd338 !important;
}
.tab-wrapper h2 {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.panel-wrapper {
  display: flex;
  min-height: 200px;
  flex-basis: 95%;
  /* padding: 20px; */
  flex-wrap: wrap;
  /* border-radius: 8px; */
  /* border: 1px solid #b8b8b8; */
  align-items: flex-start;
  /* outline: 1px solid red; */
  z-index: 10;
  margin-top: -2px;
}
.merch--panel-wrapper{
  margin-top: 0px;
}
.panel-wrapper .panel {
  flex-basis: 100%;
  padding: 20px;
  flex-wrap: wrap;
  display: none;
  border: 1px solid #d8d8d8;
  border-radius: 0px !important;
}
.panel-wrapper .panel h3 {
  font-family: cursive;
  /* display: flex; */
  flex-basis: 100%;
}
.panel-wrapper .panel .panel-body {
  display: flex;
  flex-basis: 100%;
}
.panel-wrapper .panel .tble-panel-body {
  display: block;
}

.panel-wrapper .panel .panel-body table {
  width: 100%;
}
.panel-wrapper .panel.active {
  display: flex;
}
.savebtns-block {
  width: 100%;
  margin-top: 10px;
  text-align: left;
}
.img-col {
  display: block;
}
.img-col img {
  display: inline-block;
  width: 100%;
}
.customer-details-block {
  padding-top: 10px;
  padding-bottom: 10px;
}
.customer-details-block .innercus {
  background: #eeeeee;
}
.deladdress-blc {
  margin-top: 15px;
}
.bottom-form {
  background-color: #efefef;
  margin: 10px 0px -10px 0px;
  padding-top: 10px;
}
.merch--deldetails{
  width: 100% !important;
}
.merch--delriderdetails{
  width: 100% !important;
}
.merch--totalvalues{
    width: 100% !important;
}
.merch--outletdetails{
  width: 100% !important;
}
/* Media queries */
@media screen and (max-width: 1675px) {
  .merch--panel-wrapper{
    width: 1200px;
  }
  .tabcont--area{
    overflow-x: scroll;
  }
}
@media screen and (max-width: 1275px) {
  .deladdress-blc--email{
    font-size: 10px;
  }
}
@media screen and (max-width: 992px) {
  .deladdress-blc--email{
    font-size: 12px;
  }
}
@media screen and (max-width: 525px) {
  .merch--deldetails th{
    font-size: 8px;
  }
  .merch--deldetails td{
    font-size: 8px;
  }
  .merch--outletdetails th{
    font-size: 8px;
  }
  .merch--outletdetails td{
    font-size: 8px;
  }
  .customer-details-block{
    font-size: 10px;
  }
  .merch--totalvalues th{
    font-size: 8px;
  }
  .merch--totalvalues td h4{
    font-size: 8px;
  }
}
@media screen and (max-width: 475px) {
  .merch--deldetails th{
    font-size: 7px;
  }
  .merch--deldetails td{
    font-size: 7px;
  }
   .merch--outletdetails th{
    font-size: 7px;
  }
  .merch--outletdetails td{
    font-size: 7px;
  }
  .merch--totalvalues th{
    font-size: 7px;
  }
  .merch--totalvalues td h4{
    font-size: 7px;
  }
}

@media screen and (max-width: 375px) {
  .merch--deldetails th{
    font-size: 4px;
  }
  .merch--deldetails td{
    font-size: 5px;
  }

}

</style>
